/*!** {*!*/
/*!*-webkit-font-smoothing: subpixel-antialiased;*!*/
/*!*-moz-osx-font-smoothing: grayscale;*!*/
/*!*text-rendering: optimizeLegibility;*!*/
/*!*font-feature-settings: 'kern';*!*/
/*!*-webkit-font-feature-settings: 'kern';*!*/
/*!*-moz-font-feature-settings: 'kern';*!*/
/*!*-moz-font-feature-settings: 'kern=1';*!*/
/*!*box-sizing: border-box;*!*/
/*!*}*!*/

html,
body {
  height: 100%;
  width: 100%;
  /*font-size: 15px;*/
}

/*@media screen and (min-resolution: 120dpi) {*/
/*  html,*/
/*  body {*/
/*    font-size: 13px;*/
/*  }*/
/*}*/

body.dragged {
  user-select: none;
}

/*.sr-only {*/
/*  position: absolute;*/
/*  width: 1px;*/
/*  height: 1px;*/
/*  padding: 0;*/
/*  overflow: hidden;*/
/*  clip: rect(0, 0, 0, 0);*/
/*  white-space: nowrap;*/
/*  -webkit-clip-path: inset(50%);*/
/*  clip-path: inset(50%);*/
/*  border: 0;*/
/*}*/
#root {
  position: relative;
  min-height: 100%;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  /*color: rgb(9, 30, 66);*/
  /*background-color: #edf2f7;*/
  /*font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',*/
  /*  'Segoe UI Emoji', 'Segoe UI Symbol';*/
  /*line-height: 1.62857142857143rem;*/
  /*font-style: normal;*/
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  text-rendering: optimizelegibility;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

/*.login {*/
/*  background-color: #2770de;*/
/*}*/

@media screen and (min-width: 0px) and (max-width: 720px) {
  .hide-mobile {
    display: none !important;
    visibility: hidden;
  }

  .show-mobile {
    display: initial;
    visibility: visible;
  }
}

@media screen and (min-width: 720px) {
  .hide-mobile {
    display: initial;
    visibility: visible;
  }

  .show-mobile {
    display: none !important;
    visibility: hidden;
  }
}

@media screen and (min-width: 0px) and (max-width: 1020px) {
  .hide-tablet {
    display: none !important;
    visibility: hidden;
  }

  .show-tablet {
    display: inherit;
    visibility: visible;
  }
}

@media screen and (min-width: 1020px) {
  .hide-tablet {
    display: inherit;
    visibility: visible;
  }

  .show-tablet {
    display: none !important;
    visibility: hidden;
  }
}

@media print {
  .hide-print,
  .hide-print * {
    display: none !important;
  }
}

.hoverable:hover {
  background-color: rgba(9, 30, 66, 0.04);
}

/*#popper[data-popper-escaped] {*/
/*  transform: translate(0, 0);*/
/*}*/
